<template>
  <!-- 侧边栏 -->
  <div style="height: 100%">
    <el-menu
      :default-openeds="['1', '3']"
      background-color="rgb(48,65,86)"
      style="min-height: 100%; border: none"
      text-color="rgb(191, 203, 217)"
      active-text-color="rgb(64, 158, 255)"
      :collapse-transition="false"
      :collapse="this.$store.state.isCollapse"
      default-active="1"
      router
    >
      <!-- 头部 -->
      <div
        style="height: 60px; line-height: 60px; text-align: center"
        class="cursor"
        @click="onHome"
      >
        <img
          src="../assets/logo.png"
          alt=""
          style="width: 20px; position: relative; top: 5px; margin-right: 5px"
        />
        <b style="color: #fff" v-show="!this.$store.state.isCollapse"
          >后台管理系统</b
        >
      </div>
      <!-- 首页 -->
      <template v-for="item in data">
        <el-menu-item
          :index="item.urlAddress"
          :key="item.id"
          v-if="!item.children.length"
        >
          <i :class="item.ico"></i>
          <span slot="title">{{ item.acitonName }}</span>
        </el-menu-item>

        <el-submenu v-else :key="item.id" :index="item.urlAddress">
          <template slot="title">
            <i :class="item.ico"></i>
            <span slot="title">{{ item.acitonName }}</span>
          </template>
          <el-menu-item-group v-for="items in item.children" :key="items.id">
            <el-menu-item :index="items.urlAddress">
              {{ items.acitonName }}
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  // name: 'Sidebar',
  props: {},
  data() {
    return {
      data: "",
      datas: "",
    };
  },
  created() {
    let sidebar = JSON.parse(window.sessionStorage.getItem("sidebar"));
    let data = [];
    let arr = [];
    this.axios.get("/admin/action/selectActionList2", {}).then((res) => {
      // sidebar.forEach((item) => {
      //   res.data.data.forEach((item2) => {
      //     item2.children.forEach((item3) => {
      //       if (item.actionId == item3.id) {
      //         arr.push(item2.id);
      //         arr.push(item3.id);
      //       }
      //     });
      //   });
      // });

      // arr = [...new Set(arr)];
      // console.log(arr);
      // let arr2=JSON.parse(JSON.stringify(res.data.data))
      // res.data.data.forEach((item,i) => {
      //   if (arr.includes(item.id)) {
      //     item.children = [];
      //     arr2[i].children.forEach((item2) => {
      //       if (arr.includes(item2.id)) {
      //         item.children.push(item2);
      //       }
      //     });
      //     data.push(item);
      //   }
      // });

      sidebar.forEach(item => {
        // console.log(item)
        res.data.data.forEach(items => {

          // console.log(items);
          if (item.actionId == items.id) {
            data.push(items)
          }
        })
      })
      this.data = data;
      sessionStorage.setItem("newsidebar", JSON.stringify(data));
      this.data = data;
      
      // console.log(this.data);
    });
  },
  watch: {
    $route: function (newVal, oldVal) {
      // console.log(newVal, oldVal);
    },
  },
  components: {
    currentRoute() {
      // console.log(this.$route);
    },
  },
  methods: {
    onHome() {
      if (!this.$route.fullPath == "/home") {
        this.$router.push("/home");
      }
    },
    getData() {},
  },
};
</script>

<style>
.el-menu-item:hover {
  background: #263445 !important;
}
.el-submenu__title:hover {
  background: #263445 !important;
}
.el-menu-item.is-active {
  background: #263445 !important;
}
.el-submenu__title.is-active {
  background: #263445 !important;
}
.el-menu-item-group .el-menu-item {
  background-color: #1f2d3d !important;
}
.el-menu-item-group__title {
  padding: 0 !important;
}

.cursor {
  cursor: pointer;
}
.sidebar::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.sidebar::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #535353;
}
.sidebar::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ededed;
}
</style>
