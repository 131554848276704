import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // name: 'Index',
    component: Index,
    children: [
      {
        path: '/',
        redirect() {
          return 'home'
        }
      },
      {
        path: 'home',
        component: () => import('../views/sidebar/home.vue')
      },
      {
        path: 'member',
        component: () => import('../views/member/member.vue')
      },
      {
        path: 'memberPrice',
        component: () => import('../views/member/memberPrice.vue')
      },
      {
        // GEN通证
        path: 'gen',
        name:'gen',
        component: () => import('../views/gen/gen.vue')
      },
      {
        // 协议通证划拨记录
        path: 'TransferRecord',
        name:'TransferRecord',
        component: () => import('../views/gen/TransferRecord.vue')
      },
      {
        // 协议通证划拨记录
        path: 'waterRecord',
        name:'waterRecord',
        component: () => import('../views/gen/waterRecord.vue')
      },
      {
        // GEN通证修改
        path: 'editGen',
        name:'editGen',
        component: () => import('../views/gen/editGen.vue')
      },
      {
        // GEN通证配置
        path: 'genAllocation',
        component: () => import('../views/gen/genAllocation.vue')
      },
      {
        // GEN企业管理
        path: 'genManagement',
        component: () => import('../views/gen/genManagement.vue')
      },
      {
        // GEN企业管理
        path: 'genManager',
        component: () => import('../views/gen/updateManager.vue')
      },
      {
        // GEN成交记录
        path: 'TransactionRecord',
        component: () => import('../views/gen/TransactionRecord.vue')
      },
      {
        // 积分回收记录
        path: 'PointsRecord',
        component: () => import('../views/gen/PointsRecord.vue')
      },
      {
        // 推荐关系
        path: 'recommend',
        component: () => import('../views/member/recommend.vue')
      },
      
      {
        path: 'digitalCertificate',
        component: () => import('../views/digitalCertificate/digitalCertificate.vue')
      },
      {
        path: 'closingPrice',
        component: () => import('../views/digitalCertificate/closingPrice.vue')
      },
      {
        path: 'partition',
        component: () => import('../views/digitalCertificate/partition.vue')
      },
      {
        // 新增数字通证管理
        path: 'addOredit',
        component: () => import('../views/digitalCertificate/addOredit.vue')
      },
      {
        // 新增收盘价配置
        path: 'closePriceaddOrEdit',
        component: () => import('../views/digitalCertificate/closePriceaddOrEdit.vue')
      },
      {
        // 新增分区类别
        path: 'addSubarea',
        component: () => import('../views/digitalCertificate/addSubarea.vue')
      },
      {
        // 新闻管理
        path: 'news',
        component: () => import('../views/newsManagement/news.vue')
      },
      {
        // 新闻管理
        path: 'addNews',
        component: () => import('../views/newsManagement/addNews.vue')
      },
      {
        // 新闻查看
        path: 'addNewssss',
        name: 'addNewssss',
        component: () => import('../views/newsManagement/addNewssss.vue')
      },
      {
        // 新闻修改
        path: 'addNewddd',
        name: 'addNewddd',
        component: () => import('../views/newsManagement/addNewddd.vue')
      },
      {
        // 新闻分类
        path: 'newsCategoryws',
        component: () => import('../views/newsManagement/newsCategory.vue')
      },
      {
        // 广告弹窗
        path: 'advertisingList',
        component: () => import('../views/newsManagement/advertisingList.vue')
      },
      {
        // 弹窗公告管理
        path: 'popupWindow',
        component: () => import('../views/newsManagement/popupWindow.vue')
      },
      {
        // 新增新闻分类
        path: 'addCategory',
        component: () => import('../views/newsManagement/addCategory.vue')
      },
      {
        // 新增修改新闻分类
        path: 'addCategoryee',
        component: () => import('../views/newsManagement/addCategoryee.vue')
      },
      {
        // 新增查看新闻分类
        path: 'addCategoryccc',
        component: () => import('../views/newsManagement/addCategoryccc.vue')
      },
      {
        // 新增管理员页面
        path: 'addadministrator',
        component: () => import('../views/administrator/addadministrator.vue')
      },
      {
        //修改管理员页面
        path: 'addadministratorcc',
        component: () => import('../views/administrator/addadministratorcc.vue')
      },
      {
        // 系统管理员管理
        path: 'administrator',
        component: () => import('../views/administrator/administrator.vue')
      },
      {
        // 版本管理
        path: 'downloadLink',
        component: () => import('../views/administrator/downloadLink.vue')
      },
      {
        // 系统菜单管理
        path: 'menuManagement',
        component: () => import('../views/administrator/menuManagement.vue')
      },
      {
        // 系统角色管理
        path: 'systemRole',
        component: () => import('../views/administrator/systemRole.vue')
      },
      {
        // 新增系统角色管理页面
        path: 'addsystemRole',
        component: () => import('../views/administrator/addsystemRole.vue')
      },
      {
        // 修改系统角色管理页面
        path: 'addsystemRoleaaa',
        component: () => import('../views/administrator/addsystemRoleaaa.vue')
      },
      {
        // 客服管理页面
        path: 'custoMer',
        component: () => import('../views/custoMer/custoMer.vue')
      },
      {
        // 充提审核
        path: 'chargeToReview',
        component: () => import('../views/financial/chargeToReview.vue')
      },
      {
        // 充值信息管理
        path: 'management',
        component: () => import('../views/financial/management.vue')
      },
      {
        // 数字通证审核
        path: 'throughReview',
        component: () => import('../views/financial/throughReview.vue')
      },
      {
        // 新增分区查看页面
        path: 'increased',
        component: () => import('../views/digitalCertificate/increased.vue')
      },
      {
        // 新增收盘价编辑页面
        path: 'addclosingPrice',
        component: () => import('../views/digitalCertificate/addclosingPrice.vue')
      },
      {
        // 项目报表管理页面
        path: 'Report',
        component: () => import('../views/Applicationmall/Report.vue')
      },
      {
        // 项目订单报表页面
        path: 'order',
        component: () => import('../views/Applicationmall/order.vue')
      },
      {
        // 策略订单报表页面
        path: 'strategy',
        component: () => import('../views/Applicationmall/strategy.vue')
      },
      {
        // 应用策略页面
        path: 'application',
        component: () => import('../views/Applicationmall/application.vue')
      },
      {
        // 数字通证溢出报表页面
        path: 'digital',
        component: () => import('../views/Applicationmall/digital.vue')
      },
      
      {
        // 配置列表页面
        path: 'configurationList',
        component: () => import('../views/configurationList/configurationList.vue')
      },
      {
        // 数字通证配置管理
        path: 'throughConfiguration',
        component: () => import('../views/throughConfiguration/throughConfiguration.vue')
      },
      {
        // 操作日志
        path: 'operationLog',
        component: () => import('../views/operationLog/operationLog.vue')
      },
      {
        // 新增数字通证配置管理页面
        path: 'AddthroughConfiguration',
        component: () => import('../views/throughConfiguration/AddthroughConfiguration.vue')
      },
      {
        // 查看数字通证配置管理页面
        path: 'checkPassCard',
        component: () => import('../views/throughConfiguration/checkPassCard.vue')
      },
      {
        // 编辑数字通证配置管理页面
        path: 'configurationPassCard',
        component: () => import('../views/throughConfiguration/configurationPassCard.vue')
      },
      {
        // 项目报表管理页面
        path: 'Report',
        component: () => import('../views/Applicationmall/Report.vue')
      },
      {
        // 项目订单报表页面
        path: 'order',
        component: () => import('../views/Applicationmall/order.vue')
      },
      {
        // 策略订单报表页面
        path: 'strategy',
        component: () => import('../views/Applicationmall/strategy.vue')
      },
      {
        // 应用策略页面
        path: 'application',
        component: () => import('../views/Applicationmall/application.vue')
      },
      {
        // 数字通证溢出报表页面
        path: 'digital',
        component: () => import('../views/Applicationmall/digital.vue')
      },
      {
        // 转频道配置
        path: 'support',
        component: () => import('../views/support/support.vue')
      },
      {
        // 帮助中心
        path: 'administration',
        component: () => import('../views/support/administration.vue')
      },
      // 帮助中心查看
      {
        path: 'adminsee',
        component: () => import('../views/support/adminsee.vue')
      },
      {
        // 转频道配置新增
        path: 'addsupport',
        component: () => import('../views/support/addsupport.vue')
      },
      // 转频道配置新增
      {
        path: 'updtadd',
        component: () => import('../views/support/updtadd.vue')
      },
      // 更新频道配置
      {
        path: 'tupdate',
        component: () => import('../views/support/tupdate.vue')
      },
      {
        // 转帮助中心新增
        path: 'addadministration',
        component: () => import('../views/support/addadministration.vue')
      },
      {
        // 活动管理
        path: 'eventManagement',
        component: () => import('../views/eventManagement/eventManagement.vue')
      },
      {
        // 企业管理
        path: 'enterpriseManagement',
        component: () => import('../views/enterpriseManagement/enterpriseManagement.vue')
      },
      {
        // 成交管理
        path: 'knockDown',
        component: () => import('../views/knockDown/knockDown.vue')
      },
      {
        // 分润管理
        path: 'shareProfit',
        component: () => import('../views/shareProfit/shareProfit.vue')
      },
      {
        // 意见反馈
        path: 'suggesTion',
        component: () => import('../views/suggesTion/suggesTion.vue')
      },
      {
        // 修改企业信息
        path: 'updateManager',
        component: () => import('../views/enterpriseManagement/updateManager.vue')
      },
      {
        // 查看企业信息
        path: 'checkManagement',
        component: () => import('../views/enterpriseManagement/checkManagement.vue')
      },
      {
        // 风险管理
        path: 'risAssessment',
        component: () => import('../views/risAssessment/risAssessment.vue')
      },
      {
        // 企业提现审核
        path: 'firmWithdraw',
        component: () => import('../views/firmWithdraw/firmWithdraw.vue')
      },
      {
        // 风险管理新增页面
        path: 'addrisAssessment',
        component: () => import('../views/risAssessment/addrisAssessment.vue')
      },
      {
        // 风险管理新增页面2
        path: 'addverifyGet',
        component: () => import('../views/risAssessment/addverifyGet.vue')
      },
      {
        // 成交管理新增页面
        path: 'addknockDown',
        component: () => import('../views/knockDown/addknockDown.vue')
      },
      {
        // 新增企业页面
        path: 'newEnterprise',
        component: () => import('../views/enterpriseManagement/newEnterprise.vue')
      },
      {
        // 新通证申购管理页面
        path: 'purchaseManagement',
        component: () => import('../views/purchaseManagement/purchaseManagement.vue')
      },
      {
        // 新通证申购订单管理页面
        path: 'purchaseOrderManagement',
        component: () => import('../views/purchaseManagement/purchaseOrderManagement.vue')
      },
      {
        // 新通证申购统计页面
        path: 'appliedStatistics',
        component: () => import('../views/purchaseManagement/appliedStatistics.vue')
      },
      {
        // 新增新通证申购管理页面
        path: 'newCardPurchase',
        component: () => import('../views/purchaseManagement/newCardPurchase.vue')
      },
      {
        // 资金流水
        path: 'userWater',
        component: () => import('../views/fundsWater/fundsWater.vue')
      },
      {
        // 手续费流水
        path: 'serverChange',
        component: () => import('../views/serverChange/serverChange.vue')
      },
      {
        // 委托管理
        path: 'entrust',
        component: () => import('../views/entrust/entrust.vue')
      },
      {
        // 查看数字通证管理页面
        path: 'checkCertificateManagement',
        component: () => import('../views/digitalCertificate/checkCertificateManagement.vue')
      },
      {
        // 编辑数字通证管理页面
        path: 'editCardManagement',
        component: () => import('../views/digitalCertificate/editCardManagement.vue')
      },
      {
        // 新增广告页面
        path: 'newAdvertis',
        component: () => import('../views/newsManagement/newAdvertis.vue')
      },
      {
        // 查看广告页面
        path: 'newAdvertisee',
        component: () => import('../views/newsManagement/newAdvertisee.vue')
      },
      {
        // 修改广告页面
        path: 'newAdvertisccc',
        component: () => import('../views/newsManagement/newAdvertisccc.vue')
      },
      {
        // 开盘时间
        path: 'openingTime',
        component: () => import('../views/administrator/openingTime.vue')
      },
      {
        // 费率说明
        path: 'rateThat',
        component: () => import('../views/rateThat/rateThat.vue')
      },
      // 权限管理
      {
        path: 'permissions',
        component: () => import('../views/permissions.vue')
      },
      {
        path: 'closingPricess',
        component: () => import('../views/financial/closingPrice.vue')
      }
    ]
  },
  {
    path: '/login',
    component: () => import('../views/login')
  }
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 报错就解开下面的注释
router.beforeEach(function (to, from, next) {
  if (!window.sessionStorage.getItem('token')) {
    if (to.path !== '/login') {
      return next('/login')
    }
  }
  next()
})

export default router
